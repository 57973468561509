import React, { useState } from "react";
// import ReactDOM from "react-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Add from "./Components/Add";
import Feedback from "./Components/Feedback";
import Graph from "./Components/Graph";

import { Row, Col, Form, } from "react-bootstrap";

// import Line from "./Components/Graph";
// import { Line } from "react-chartjs-2";

// class App extends React.Component {
function App() {
  const [age, setAge] = useState(0);
  const [retirement, setRetirement] = useState(0);
  const [expenses, setExpenses] = useState(0);
  const [dividend, setDividend] = useState(0);
  const [inflasion, setInflasion] = useState(0);


  var d = new Date();
  var currentYear = d.getFullYear();

  let years = retirement - age;
  let inflasionDeci = inflasion / 100 + 1;
  let inflasionOverTime = Math.pow(inflasionDeci, years);
  let newExpenses = Math.round(expenses * inflasionOverTime);
  let nE2 = newExpenses.toLocaleString().replace(".", ",");
  let dividendNeeded = (newExpenses * 12)
    .toLocaleString()
    .replace(".", ",")
    .replace(".", ",");
  let x = (((newExpenses * 12) / dividend) * 100);
  let xComma = x
    .toLocaleString()
    .replace(".", ",")
    .replace(".", ",")
    .replace(".", ",");



  return (
    <div className="App">
      <header>
        <div className="container headerCon">
          <Row>
            <Col lg="6 text-left">
              <h1>Dividend-living </h1>
              <h2>DividendCalculator</h2>
            </Col>
          </Row>
          <hr />
        </div>
      </header>
      <div className="wrapper">
        <div className="container my-5">
          <Row>
            <Col lg="6">
              <h2>
                Figure Out How Much U Need In Order To Live Off Your Dividends!
              </h2>
            </Col>
          </Row>
          <Row className="d-flex justify-content-between">
            <Col lg="5 input">
              {/* <form> */}
              <Row>
                <Col>
                  <input
                    pattern="[0-9]"
                    required
                    type="number"
                    onChange={(e) => setAge(e.target.value)}
                  />
                  <label>Your age</label>
                </Col>
              </Row>

              <Row>
                <Col>
                  <input
                    pattern="[0-9]"
                    required
                    type="number"
                    onChange={(e) => setRetirement(e.target.value)}
                  />
                  <label>Your disired age of retirement</label>
                </Col>
              </Row>

              <Row>
                <Col>
                  <input
                    pattern="[0-9]"
                    required
                    type="number"
                    onChange={(e) => setExpenses(e.target.value)}
                  />
                  <label>Your living expenses on a monthly bacis</label>
                </Col>
              </Row>

              <Row>
                <Col>
                  <input
                    pattern="[0-9]"
                    required
                    type="number"
                    onChange={(e) => setDividend(e.target.value)}
                  />
                  <label>Avg. yield in %</label>
                </Col>
              </Row>

              <Row>
                <Col>
                  <input
                    required
                    type="text"
                    onChange={(e) => setInflasion(e.target.value)}
                  />
                  <label>Rate of inflassion ( Avg. 2.5% )</label>
                </Col>
              </Row>

              <Row>
                <Col>
                  <button>RETIRE</button>
                </Col>
              </Row>
              {/* </form> */}
            </Col>
            <Col lg="5 output">
              <Row>
                <Col>
                  By the year of your retirement {years + currentYear} your
                  monthly expenses is estimated to be <b>{nE2}</b> due to
                  inflasion
                </Col>
              </Row>

              <Row>
                <Col className="dividendOutput">
                  Annual dividend needed by {years + currentYear}
                  <h4>{dividendNeeded}</h4>
                  <hr />
                </Col>
              </Row>

              <Row>
                <Col className="capitalOutput">
                  Market value needed by {years + currentYear}
                  <h4>{xComma}</h4>
                  <hr />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Add />
        <Graph />
        <Feedback />
        <div id="footer" className="container-fluid pb-4">
          <div className="container pt-5">
            <p className="text-light text-muted">Note that the content on this site should not be considered investment advice. Investing is speculative. When investing your capital is at risk. This site is not intended for use in jurisdictions in which the trading or investments described are prohibited and should only be used by such persons and in such ways as are legally permitted.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
