import React from "react"
import "../style/Add.css"

function Add() {

    return(
        <aside id="add">
            <div className="container-fluid bg-dark my-5">
                <div className="row p-5">
                    <div className="col-12 d-flex justify-content-center">
                        <h2 className="text-light">Learn more about investing</h2>
                    </div>
                </div>
            </div>
        </aside>
    );
}

export default Add;