import React from "react";

import "../style/Feedback.css"

import {Col, Row, Form, Button } from "react-bootstrap";

export default class MyForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ""
    };
  }

  render() {
    const { status } = this.state;
    return (
      <form
        onSubmit={this.submitForm}
        action="https://formspree.io/mvowwkby"
        method="POST"
      >
        {/* <!-- add your custom form HTML here --> */}

        <div className="feedbackWrapper">
            <aside className="bg-dark">
                    <Col className="container-fluid">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                            <h2 className="text-light my-5">All feedback is apreciated</h2>
                        </div>
                        <div className="col-lg-4 offset-lg-4 text-left text-light mb-5">
                            <Row>
                                <Col className="col-6">
                                    <label className="d-block">Name:</label>
                                    <input type="text" name="name" className="w-100"/>
                                </Col>
                                <Col className="col-6">
                                    <label className="d-block">Email:</label>
                                    <input type="email" name="email" className="w-100"/>
                                </Col>
                            </Row>

                            <label className="d-block mt-3">Message:</label>
                            <textarea type="text" name="message" cols="40" rows="7" className="w-100"></textarea>

                            <div className="text-center mt-4">
                                {status === "SUCCESS" ? <p>Thanks!</p> : <button className="bg-dark text-light">Submit</button>}
                                {status === "ERROR" && <p>Ooops! There was an error.</p>}
                            </div>
                        </div>
                    </div>
                </Col>

            </aside>
        </div>
      </form>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}