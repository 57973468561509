import React, { useState } from "react";
import "../App.css";
import "../style/Graph.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { Row, Col, } from "react-bootstrap";

// import Line from "./Components/Graph";
import { Line } from "react-chartjs-2";

// class App extends React.Component {
function Graph() {
  // const [age, setAge] = useState(0);
  // const [retirement, setRetirement] = useState(0);
  // const [expenses, setExpenses] = useState(0);
  // const [dividend, setDividend] = useState(0);
  // const [inflasion, setInflasion] = useState(0);
  
  
  const [capital, setCapital] = useState(0);
  const [monthlyPay, setMonthlyPay] = useState(0);
  const [growth, setGrowth] = useState(0);
  const [growthOutput, setGrowthOutput] = useState(0);
  const [test1, setTest1] = useState(0);
  const [test2, setTest2] = useState(0);


  //states for graph
  const [capitalRetired, setCapitalRetired] = useState(0);
  const [capitalRetiredC, setCapitalRetiredC] = useState(0);
  const [ownPayment, setOwnPayment] = useState(0);
  const [ownPaymentC, setOwnPaymentC] = useState(0);

  const [arrayYears, setArrayYears] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);


  const [graph, setGraph] = useState([""]);

  var d = new Date();
  var currentYear = d.getFullYear();

  var years = test2 - test1;

  // let years = retirement - age;
  // let inflasionDeci = inflasion / 100 + 1;
  // let inflasionOverTime = Math.pow(inflasionDeci, years);
  // let newExpenses = Math.round(expenses * inflasionOverTime);
  // let nE2 = newExpenses.toLocaleString().replace(".", ",");
  // let dividendNeeded = (newExpenses * 12)
  //   .toLocaleString()
  //   .replace(".", ",")
  //   .replace(".", ",");
  // let x = (((newExpenses * 12) / dividend) * 100);
  // let xComma = x
  //   .toLocaleString()
  //   .replace(".", ",")
  //   .replace(".", ",")
  //   .replace(".", ",");

  // let isTrue = false;

  let growthDeci = growth / 100 + 1;


  //chart
  



  var data = {
    labels: arrayYears,
    datasets: [
      {
        label: "Marked value over time",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgb(94, 182, 147)",
        borderColor: "rgb(94, 182, 147)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgb(94, 182, 147)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgb(94, 182, 147)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: graph,
      },
    ],
  };

  // }
  function test() {
    // console.log("capital" + " " + capital);
    // console.log("growthDeci" + " " + growthDeci);
    // console.log("monthly pay" + " " + monthlyPay);
    
    // console.log(intX);
    let totAnnualPay = parseFloat(monthlyPay)*12;
    let totYear1 = totAnnualPay + parseFloat(capital);


    var yearStart = currentYear +1;
    var yearEnd = currentYear + years;

    
    var arr = [];

    while(yearStart < yearEnd+1){
      arr.push(yearStart++);
      setArrayYears(arr);
    }

    var capitalstart = 1;
    var arr2 = [];
    while (capitalstart === 1){
      arr2.push(totYear1 * growthDeci);
      capitalstart++
    }


    while (capitalstart <= years && capitalstart !== 1){
        arr2.push((arr2.slice(-1).pop() + totAnnualPay) * growthDeci);
        capitalstart++
        
      }
  
    // console.log(arr2);


    if (capital && years && growth && monthlyPay != null) {
      setGraph(arr2);
      setCapitalRetired(Math.round(arr2.slice(-1).pop()));
      setCapitalRetiredC(capitalRetired
        .toLocaleString()
        .replace(".", ",")
        .replace(".", ","))

        setOwnPayment(years * totAnnualPay + parseFloat(capital));
        setOwnPaymentC(ownPayment
          .toLocaleString()
          .replace(".", ",")
          .replace(".", ","))

      setGrowthOutput(growth);



    } else {
      // alert('Please enter "Your age", "Disired age of retiremen", "Starting capital", "Monthly payment" and a "Growthrate" ');
    }
  }


  return (
    <div className="App">
      
      <div id="graphWrapper">
        
        <div className="container mt-5">
          
          <Row className="d-flex justify-content-center">


            <Col lg="12" className="input  my-5">
            <Row>
            <Col lg={{span:8, offset:2}} >
              <h2 className="text-center">
                Let Your Money Work You With The Power Of Compound Interest. Watch It Grow! 
              </h2>
            </Col>
          </Row>  
              <form onChange={test} className="w-100">
                <Row className="d-flex">

                  <Col sm={12} lg="12" >
                    <Row>
                      <Col lg="4">
                        <input
                        className="text-center"
                          pattern="[0-9]"
                          required
                          type="number"
                          onChange={(e) => setCapital(e.target.value)}
                        />
                        <label>Your starting capital</label>
                      </Col>
                    
                      <Col lg="4">
                        <input
                        className="text-center"
                          pattern="[0-9]"
                          required
                          type="number"
                          onChange={(e) => setMonthlyPay(e.target.value)}
                        />
                        <label>Your monthly payment</label>
                      </Col>
                      {/* <Col className="d-flex" lg="4" sm="12"> */}
                      <Col lg="2" sm="6">
                        <input
                        className="text-center"
                          pattern="[0-9]"
                          required
                          type="number"
                          onChange={(e) => setTest1(e.target.value)}
                        />
                        <label>age</label>
                      </Col>

                      <Col lg="2" sm="6">
                        <input 
                        className="text-center"
                          pattern="[0-9]"
                          required
                          type="number"
                          onChange={(e) => setTest2(e.target.value)}
                        />
                        <label>age of retirement</label>
                      </Col>
                  {/* </Col> */}
                    </Row>
                  </Col>


                  
                  
                  <Col className="mt-5 mx-auto" lg="6">
                    <Row className="">
                      <Col lg="12" id="test" className="growthrateInput slidecontainer">
                        <input
                          id="myRange"
                          className="slider"
                          onChange={(e) => setGrowth(e.target.value)}
                          type="range"
                          min="1"
                          max="20"
                          step="0.2"
                        />
                        <label id="growthrateLabel">Growth rate {growthOutput} %</label>
                        <p className="text-muted">Change growthrate to update graph</p>
                      </Col>
                    </Row>
                  </Col>

                </Row>
              </form>
            </Col>
          </Row>
          <Row>
            <Col lg="12" className="chart-container d-flex align-content-center flex-wrap w-100 my-5" >
              {/* <h2>Line Example</h2> */}
              <Line data={data} />
            </Col>
          </Row>
          <Row className="my-5">
            <Col className="input capitalRetired col-sm-6 col-md-6 col-lg-4">
              <label>Maket value by retirement</label>
              <h3>{(capitalRetired
                .toLocaleString()
                .replace(".", ",")
                .replace(".", ","))}
              </h3>
              <hr/>
            </Col>
            <Col className="input ownPayment col-sm-6 col-md-6 col-lg-4">
              <label>Out off pocket money</label>
              <h3>{ownPaymentC}</h3>
              <hr/>
            </Col>
            <Col className="actualGain">
              <label>Actual gain</label>
              <h3>{(capitalRetired-ownPayment)
                .toLocaleString()
                .replace(".", ",")
                .replace(".", ",")}
                </h3>
              <hr/>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Graph;
